
.compare-pannel {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 400px;
    margin-top: 40px;
    margin-left: 70px;
    position: fixed;
    background-color: white; 
    z-index: 2; 
    padding: 24px 16px;
    overflow: scroll;
    border-right: 1px solid rgba(26, 26, 0, 0.16);
    bottom: 20px;
  }
  .download-container{
    margin-bottom: 2vh;
  }
  .custom-select{
    font-size: 14px;
    text-align: left;
    
 }
 .select-label {
    text-align: left;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.04px;
    margin-bottom: 8px; /* Add some space between label and select */
  }
  .compareecondarytext
{
  color: rgba(7, 6, 0, 0.58);
font-family: "Inter-Medium", Helvetica;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 20px;
text-align: initial;
margin-bottom: 18px;
}
.date-row {
  display: flex;
  gap: 20px;
}

.date-item {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.date-label {
  text-align: left;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.04px;
  margin-bottom: 8px; /* Add some space between label and select */
}
.date-picker {
  flex: 1;
  margin-right: 10px;
}

.date-picker:last-child {
  margin-right: 0;
}
.compare-button-container
{
  bottom: 0px;
}
.compared-button-container {
  display: flex;
  width: 311px;
  padding: 16px 0px;
  flex-direction: column;
  align-items: center;
  gap: 26px;
  position: fixed;
}

.comparedataset-button:disabled {
  background-color: rgba(20, 20, 0, 0.05);
  color:  rgba(30, 25, 1, 0.24); 
}

.comparedataset-button:not(:disabled) {
  background-color: #0880EA;
  color: #ffffff; 

}
.comparedataset-button:hover {
  background-color: #0880EA;
  color: #ffffff; 

}

.comparedataset-button {
  width: 311px;
  border-radius: 4px;
  background: rgba(4, 4, 0, 0.91);
  color: #ffffff; 
  display: flex;
  height: 40px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  cursor: pointer;
  transition: none;
}
.resetbutton
{margin-top: 10px;
color:  #1C2024;
font-feature-settings: 'salt' on;
font-family: "Inter-Medium", Helvetica;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.selectmodule
{
  display: flex;
  width: 332px;
  padding: 16px;
  align-items: center;
  gap: 12px;
  position: absolute;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgba(7, 6, 0, 0.58);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  border-radius: 6px;
  background: #E5ECF0; 
  z-index: 2;

}.table-heading {
  font-family: Inter, sans-serif;
  font-size: 12px; /* Font size reduced to 12px */
  font-weight: 600;
  line-height: 16px;
  letter-spacing: -0.04px;
  text-align: left;
  margin-bottom: 16px;
}

.avg-price-table {
  width: 100%;
  border-collapse: collapse;
}

.avg-price-table th,
.avg-price-table td {
  height: 32px;
  padding: 0 8px;
  text-align: left;
  font-size: 10px; /* Font size for table content */
}

.avg-price-table thead th {
  background-color: #113264;
  color: white;
}

.avg-price-table tbody tr td:first-child {
  font-weight: bold;
}

.avg-price-table tr {
  border-bottom: 1px solid #ddd;
}

.avg-price-table th,
.avg-price-table td:not(:first-child) {
  border-right: none;
}

.avg-price-table tbody tr:last-child {
  border-bottom: none;
}

.mandatory {
  color: red;
  margin-left: 5px;
}
